<template>
    <div class="tag-cloud">
        <svg ref="rect" :width="width" :height="height" @mousemove="onMouseMove" @mouseout="stop" @mouseover="start">
            <rect x="0" y="0" :fill="bgColor" :width="width" :height="height" ></rect>
            <text :x="entry.x" :y="entry.y" :fill="fontColor" v-for="(entry, index) in items" :key="index" class="tag" :style="tagStyle" text-anchor="middle" :opacity="entry.opacity">
              {{entries[index].label}}
            </text>
        </svg>
    </div>
</template>
<script>
  const MATHPI180 = Math.PI / 180;
  export default {
    props: {
      entries: {
        type: Array,
        default: () => {
          []
        }
      }
    },
    mounted() {
      window.addEventListener( 'resize', this.resizeCloud );
      this.resizeCloud();
    },
    created() {
      this.addItems();
    },
    data() {
      return {
        timer: null,
        globalID: null,
        width: 550,
        height: 550,
        radiusMin: 75,
        bgDraw: true,
        bgColor: '#fff',
        opacityOver: 1.00,
        opacityOut: 0.05,
        opacitySpeed: 6,
        fov: 800,
        fontFamily: 'Oswald, Arial, sans-serif',
        fontSize: '18',
        fontColor: '#79c0ff',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        fontToUpperCase: true,
        tooltipFontFamily: 'Oswald, Arial, sans-serif',
        tooltipFontSize: '11',
        tooltipFontColor: '#fff',
        tooltipFontWeight: 'normal',
        tooltipFontStyle: 'normal',
        tooltipFontStretch: 'normal',
        tooltipFontToUpperCase: false,
        tooltipTextAnchor: 'left',
        tooltipDiffX: 0,
        tooltipDiffY: 10,
        items: [],
        radius: null,
        diameter: null,
        center3D: {x: 0, y: 0, z: 0},
        speed: {x: 0, y: 0},
        position: {sx: 0, cx: 0, sy: 0, cy: 0},
        mousePos: {x: 0, y: 0}
      }
    },
    methods: {
      init() {
        this.height = this.$el.clientWidth;
        this.width  = this.$el.clientWidth;
        this.speed.x = 2 / this.center2D.x;
        this.speed.y = 2 / this.center2D.y;
        this.diameter = this.height / 100 * 80;
        this.radius = this.diameter / 2;
        this.fontSize = this.diameter < 380 ? '13': '18'
        for (let i = 0, l = this.items.length; i < l; i++) {
          const entry = this.items[i];
          const dx = entry.px - this.center3D.x;
          const dy = entry.py - this.center3D.y;
          const dz = entry.pz - this.center3D.z;
          const length = Math.sqrt(dx * dx + dy * dy + dz * dz);
          entry.px /= length;
          entry.py /= length;
          entry.pz /= length;
          entry.px *= this.radius;
          entry.py *= this.radius;
          entry.pz *= this.radius;
        }
      },
      animloop(){
        this.drawCloud();
        this.globalID = requestAnimationFrame(this.animloop);
      },
      onMouseMove(e) {
        const rect = this.$refs.rect.getBoundingClientRect();
        this.mousePos = { x: e.clientX - rect.left, y: e.clientY - rect.top }
      },
      addItems() {
        for (let i = 1, l = this.entries.length + 1; i < l; i++) {
          const phi = Math.acos(-1 + (2 * i) / l);
          const theta = Math.sqrt(l * Math.PI) * phi;
          const x = Math.cos(theta) * Math.sin(phi);
          const y = Math.sin(theta) * Math.sin(phi);
          const z = Math.cos(phi);
          this.items.push({ index: (i - 1), px: x, py: y, pz: z, x: 0, y: 0, opacity: 0 })
        }
      },
      stop() {
        cancelAnimationFrame(this.globalID);
      },
      start() {
        this.globalID = requestAnimationFrame(this.animloop);
      },
      resizeCloud() {
        this.init();
        this.drawCloud();
      },
      drawCloud() {
        const fx = this.speed.x * this.mousePos.x - 2;
        const fy = 2 - this.speed.y * this.mousePos.y;
        const angleX = fx * MATHPI180;
        const angleY = fy * MATHPI180;
        this.position.sx = Math.sin(angleX);
        this.position.cx = Math.cos(angleX);
        this.position.sy = Math.sin(angleY);
        this.position.cy = Math.cos(angleY);
        for (let i = 0, l = this.items.length; i < l; i++) {
          const entry = this.items[i]
          const rx = entry.px;
          const rz = entry.py * this.position.sy + entry.pz * this.position.cy;
          entry.px = rx * this.position.cx + rz * this.position.sx;
          entry.py = entry.py * this.position.cy + entry.pz * -this.position.sy;
          entry.pz = rx * -this.position.sx + rz * this.position.cx;
          const scale = this.fov / (this.fov + entry.pz);
          entry.x =  (entry.px * scale + this.center2D.x)
          entry.y = (entry.py * scale + this.center2D.y)
          entry.opacity = (this.radius - entry.pz) / this.diameter
          this.$set(this.items, i, entry)
        }
      }
    },
    computed: {
      center2D() {
        return { x: this.width / 2, y: this.height / 2 };
      },
      tagStyle() {
        return {
          font: `normal ${this.fontSize}px Oswald, Arial, sans-serif`
        }
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resizeCloud);
    },
  }
</script>
<style lang="scss">
  .tag-cloud{
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tag {
    color: #444;
  }
</style>