<template>
  <div class="stretchy-wrapper">
    <div>
      <div class="image-slide-container">
        <nav class="arrows">
          <span class="arrows-prev" @click="prev"></span>
          <span class="arrows-next" @click="next"></span>
        </nav>
        <transition-group :name="trans" tag="div" class="image-slider">
          <div v-for="num in [currentNumber]" :key="num" class="slide" :style="imageStyle">
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      images: {
        type: Array,
        default: () => {
          []
        }
      }
    },
    computed: {
      imageStyle() {
        return {
          backgroundImage: `url(${this.images[Math.abs(this.currentNumber) % this.images.length]})`
        };
      }
    },
    data() {
      return {
        currentNumber: 0,
        trans: 'slide-next'
      };
    },
    methods: {
      prev() {
        this.currentNumber -= 1;
        this.trans = "slide-prev";
      },
      next() {
        this.currentNumber += 1;
        this.trans = "slide-next";
      }
    }
  }
</script>