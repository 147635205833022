<template>
  <div class="work">
    <transition name="fade" enter-active-class="fadeUpIn" leave-active-class="fadeOutBottom">
      <div class="anim slide-title" v-if="isIn">PROJECTS</div>
    </transition>
    <div class="inner-content">
      <div class="container-fluid scroll-container">
        <div class="text-center"><p>Some of my side works and Open Source projects</p></div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12" v-for="(pf, index) in projects" :key="pf.id">
            <div class="gallery_product" @click="openPopup(index)">
              <img :src="require( `../assets/img/work/${pf.display}.png`)" class="img-fluid">
              <div class="project-text">{{pf.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopupModal v-model="project.popup">
      <div class="container-fluid scroll-container">
        <div class="row">
          <div class="col-lg-6 p-6 mt-3">
            <image-slider v-if="project.popup" :images="project.slider||[]"></image-slider>
          </div>
          <div class="col-lg-6 p-6 mt-3" v-html="project.content">
          </div>
        </div>
      </div>
    </PopupModal>
  </div>
</template>
<script>
  import viewMixin from './viewMixin'
  import ImageSlider from '../components/ImageSlider.vue'
  import PopupModal from '../components/PopupModal.vue'
  export default {
    name: 'Work',
    mixins: [viewMixin],
    data() {
       return {
         project: {},
         projects: [
           {
             id: 'p1',
             popup: false,
             title: 'One Page Bootstrap 4 Template',
             display: 'p1_main',
             slider: [require('@/assets/img/work/p1_s1.png'),require('@/assets/img/work/p1_s2.png'),require('@/assets/img/work/p1_s3.png')],
             content: `<h5 class="project-title">CrystalDrop - MultiPurpose Onepage Bootstrap 4 Template</h5><p>CrystalDrop is a multipurpose Bootstrap template, fully responsive, created with the intent of being the easiest and most customizable multipurpose onepage theme. It can be used by creative agencies, personal profile, corporate looking for a simple and clean one page template.<br>
              <b>Main Features:</b></p><ul><li>Powered with Bootstrap 4 Latest Version, HTML5, CSS3, jQuery, Sass, Gulp</li><li>100% Responsive, Clean and Modern Design</li>
              <li>16 One Page HTML Templates with 15 predefined color scheme, you can create new one easily and they have separate css files.</li>
              <li>Easy to Customize</li></ul><p><b>Technologies:</b> Bootstrap 4, Gulp 4, SCSS, jQuery, MailChimp, PHP, Contact Form with PHP Mailer</p><button type="button" class="btn btn-outline" onclick=" window.open('http://helloiamjayesh.com/crystaldrop/', '_blank'); return false;">Demo Site</button>
              `
           },
           {
             id: 'p2',
             popup: false,
             title: 'Fireform Builder',
             display: 'p2_main',
             slider:[require('@/assets/img/work/p2_s1.png'),require('@/assets/img/work/p2_s2.png'),require('@/assets/img/work/p2_s3.png'),require('@/assets/img/work/p2_s4.png'),require('@/assets/img/work/p2_s5.png')],
             content: `<h5 class="project-title">FireformBuilder - A drag and drop online form builder</h5><p>FireformBuilder enables you to create beautiful HTML forms with material design with drag and drop feature and without any coding.<br>
              <b>Main Features:</b></p><ul><li>Simple,responsive and customizable form</li><li>20+ form fields and elements and more to come.</li><li>Colect data from client through fill in forms.</li>
              <li>Realtime data capture with Firestore.</li></ul><p><b>Technologies:</b> VueJS, Vuex, Vue Router, SCSS/Stylus, Quasar Framework, Firestore, Google Function</p>
              <button type="button" class="btn btn-outline" onclick="window.open('https://fireformbuilder.firebaseapp.com/', '_blank'); return false;">Demo Site</button>
              `
           },
           {
             id: 'p3',
             popup: false,
             title: 'Resume Builder',
             display: 'p3_main',
             slider:[require('@/assets/img/work/p3_main.png'),require('@/assets/img/work/p3_s2.png'),require('@/assets/img/work/p3_s3.png'),require('@/assets/img/work/p3_s4.png')],
             content: `<h5 class="project-title">Resume Builder</h5><p>Create beautiful resumes in minutes by selecting from various resume template layout and fill in your details and print and download it.<br>
              <b>Main Features:</b></p><ul><li>Select from pre formatted resume templates.</li><li>Fill out your resume summary, experience, education, skills and more.</li>
              <li>Easy to edit and change the resume template.</li><li>Print, download and share resume.</li><li>Currently it's work in progress more features to be included.</li></ul><p><b>Technologies:</b> Vue.js, Vuex, Element UI library, Firebase</p>
              <button type="button" class="btn btn-outline" onclick="window.open('http://helloiamjayesh.com/ResumeBuilder/index.html', '_blank'); return false;">Demo Site</button>
              `
           },
           {
             id: 'p4',
             popup: false,
             title: 'Vue Crypto Dashboard',
             display: 'p4_main',
             slider:[require('@/assets/img/work/p4_main.png'),require('@/assets/img/work/p4_s2.png')],
             content: `<h5 class="project-title">Vue Crypto Dashboard</h5><p>Vue Crypto Dashboard showcasing realtime crypto currency prices from Binance Exchange using custom websocket API, display historical charts with on amChart component and News feed from Cryptocompare site<br>
              <b>Main Features:</b></p><ul><li>Responsive and beautiful design with Bootstrap 4</li><li>Realtime cryptocurency prices from binance exchange using custom websocket api build on top of HTML Websocket</li>
              <li>Showing historical line and candlestick charts using amChart library</li><li>Display Cryptocurrency News through Cryptocompare New API</li><li>Created Sparkline SVG chart component</li></ul>
              <p><b>Technologies:</b>Vue.js, Bootstrap 4, SCSS, Websocket, SVG, Travis CI, amChart</p><button type="button" class="btn btn-outline" onclick="window.open('https://jayeshlab.github.io/vue-crypto-dashboard/', '_blank'); return false;">Demo</button> <button type="button" class="btn btn-outline ml-3" onclick="window.open('https://github.com/JayeshLab/vue-crypto-dashboard', '_blank'); return false;">Github</button>
              `
           },
           {
             id: 'p5',
             popup: false,
             title: 'Bootstrap Portfolio Template',
             display: 'p5_main',
             slider:[require('@/assets/img/work/p5_s1.png'),require('@/assets/img/work/p5_s2.png'),require('@/assets/img/work/p5_s3.png'),require('@/assets/img/work/p5_s4.png'), require('@/assets/img/work/p5_s4.png')],
             content: `<h5 class="project-title">CrystalFolio - One Page Portfolio template</h5><p>CrystalFolio is a creative vCard / Resume / CV HTML template with color options and 4 background variant pages with card stake layout.
              The design is simple, clean and mobile friendly. CrystalFolio is based on responsive latest Bootstrap 4 and modern web technologies HTML5, CSS3, jQuery and Sass. It is easily customizable. <br>
              </p><b>Main Features:</b><ul><li>Powered with Bootstrap 4 Latest Version, HTML5, CSS3, jQuery, Sass, Gulp</li><li>100% Responsive, Clean and Modern Design</li><li>Easy to Customize</li>
              </ul><p><b>Technologies:</b> Bootstrap 4, Gulp 4, SCSS, Font Awesome Icon, Linear Icon, jQuery,jQuery Cardslider, jQuery filterizr plugin, Owl Carousel, Magnific Popup, Google Map, MailChimp, PHP, Contact Form with PHP Mailer</p>
              <button type="button" class="btn btn-outline" onclick="window.open('http://helloiamjayesh.com/crystalfolio/', '_blank'); return false;">Demo Site</button>
              `
           },
           {
             id: 'p6',
             popup: false,
             title: 'Open Source Projects',
             display: 'p6_main',
             slider:[require('@/assets/img/work/p6_main.png')],
             content: `<h5 class="project-title">OpenSource Projects</h5>
              <ul class="list-group"><li class="list-group-item"><b>vue-numeric-input</b> - Number Input component with controls for vue</li><li class="list-group-item"><b>vue-resize-text</b> - A vue directive which automatically resize font size based on element width.</li>
                <li class="list-group-item"><b>bootstrap-4-sass-gulp-4-boilerplate</b> - A bootstrap 4 boilerplate with scss, gulp 4</li><li class="list-group-item"><b>vue-crypto-dashboard </b> - A crypto currency dashboard to view real-time prices of crypto pair from binance exchange using websocket api</li>
                <li class="list-group-item"><b>vue-page-note </b> - A single page note taking application with rich text box with resize, rotate and move feature to take notes. User can copy, paste and edit their notes on page and save it locally</li>
              </ul><button type="button" class="btn btn-outline" onclick="window.open('https://github.com/JayeshLab', '_blank'); return false;">Github</button>
              `
           }
         ]
       }
    },
    methods: {
      openPopup(index) {
        this.project = this.projects[index]
        this.project.popup = true;
      }
    },
    components: {
      PopupModal,
      ImageSlider
    }
  }
</script>