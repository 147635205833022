<template>
    <div class="text-wrapper">
        <span v-for="(c,index) in Array.from(text)" :key="index" :class="[anim]">{{c}}</span>
        <slot></slot>
    </div>
</template>
<script>
  export default {
    props: {
      text: {
        type:String,
        default: 'Hello'
      },
      anim: {
        type: String,
        default: 'text-anim'
      }
    }
  }
</script>