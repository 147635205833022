<template>
    <div class="skills">
        <transition name="fade" enter-active-class="fadeUpIn" leave-active-class="fadeOutBottom">
            <div class="anim slide-title" v-if="isIn">SKILLS</div>
        </transition>
      <div class="inner-content">
        <div class="container-fluid scroll-container">
          <div class="row h-100">
            <div :class="[showTagCloud ? 'col-6':'col-12']">
              <p>I have hands on experience as a full stack web developer with a passion for UI design.</p>
              <ul type="circle" class="skill-desc">
                <li><b>Frontend Stack:</b> HTML5, Javascript, ES6/ES7, Typescript, VueJS, Vuex, Vue UI Framework (ElementUI, Vuetify, Quasar Framework), Electron, jQuery, Bootstrap, ActionScript, Flex, CSS3/SCSS/Less.</li>
                <li><b>Backend Stack:</b> NodeJS, PHP, Java/J2EE, Spring Boot, Python.</li>
                <li><b>Databases:</b> Experience with relational database like MySQL, Oracle, Sybase and PL/SQL and NoSQL like MongoDB, Redis, Firebase</li>
                <li>Well versed with build and CI tools such as Gulp, Webpack, NPM, Jenkins, Travis CI, Bitbucket Pipeline.</li>
                <li>Familiar with Git, Bitbucket version control.</li>
                <li>Experienced practitioner of test driven development with Jest, Vue Test Util, Mocha, Karma, JUnit, Mockito.</li>
                <li>Knowledge of graphics software - Photoshop, Illustrator, Sketch</li>
                <li>Experience with clouds technologies (AWS, Google Cloud, Heroku, Docker, Kubernetes)</li>
                <li>Experience with IOT devices such as Arduino, Raspberry Pi, NodeMCU ESP8266 and sensors</li>
              </ul>
            </div>
            <div class="col-6" v-if="showTagCloud">
              <TagCloud :entries="entries" v-if="isIn && showTagCloud"></TagCloud>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
  import viewMixin from './viewMixin'
  import TagCloud from '../components/TagCloud.vue'
  export default {
    mixins: [viewMixin],
    data() {
      return {
        width: 0,
        showTagCloud: false,
        entries: [{ label: 'Vue.js', url: '', target: '_top' },
          { label: 'Javascript', url: '', target: '_top' },
          { label: 'Java', url: '', target: '_top' },
          { label: 'J2EE', url: '', target: '_top' },
          { label: 'ES6/ES7', url: '', target: '_top' },
          { label: 'SCSS', url: '', target: '_top' },
          { label: 'Less', url: '', target: '_top' },
          { label: 'Webpack', url: '', target: '_top' },
          { label: 'Gulp', url: '', target: '_top' },
          { label: 'Jest', url: '', target: '_top' },
          { label: 'Spring Boot', url: '', target: '_top' },
          { label: 'Node.js', url: '', target: '_top' },
          { label: 'Electron', url: '', target: '_top' },
          { label: 'GraphQL', url: '', target: '_top' },
          { label: 'jQuery', url: '', target: '_top' },
          { label: 'Github', url: '', target: '_top' },
          { label: 'MongoDB', url: '', target: '_top' },
          { label: 'MySQL', url: '', target: '_top' },
          { label: 'Oracle', url: '', target: '_top' },
          { label: 'Git', url: '', target: '_top' },
          { label: 'TravisCI', url: '', target: '_top' },
          { label: 'Vuex', url: '', target: '_top' },
          { label: 'Docker', url: '', target: '_top' },
          { label: 'AWS', url: '', target: '_top' },
          { label: 'Firebase', url: '', target: '_top' },
          { label: 'PHP', url: '', target: '_top' },
          { label: 'Python', url: '', target: '_top' },
          { label: 'PL/SQL', url: '', target: '_top' },
          { label: 'HTML5', url: '', target: '_top' },
          { label: 'NPM', url: '', target: '_top' },
          { label: 'Jenkin', url: '', target: '_top' },
          { label: 'Maven', url: '', target: '_top' },
          { label: 'Flex', url: '', target: '_top' },
          { label: 'ActionScript', url: '', target: '_top' },
          { label: 'IOT', url: '', target: '_top' },
          { label: 'Bootstrap', url: '', target: '_top' }]
      }
    },
    created() {
      window.addEventListener("resize", this.resizeHandler);
      this.resizeHandler();
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.resizeHandler);
    },
    methods: {
      resizeHandler() {
         this.width = window.innerWidth;
         if(this.width < 991.98)
         {
          this.showTagCloud = false;
         }
         else {
          this.showTagCloud = true;
         }
      }
    },
    components: {
      TagCloud
    }
  }
</script>