<template>
    <div class="loader" v-if="loading">
       <img src="@/assets/loading.svg" style="width: 200px; height: 200px"/>
    </div>
</template>
<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: false
      }
    }
  }
</script>