export default {
  data: function () {
    return {
      isIn : false,
      isOut: false
    };
  },
  methods: {
    enterAnimation() {
      this.isIn = true;
      this.isOut = false;
    },
    leaveAnimation() {
      this.isIn = false;
      this.isOut = true;
    }
  }
}