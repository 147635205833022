<template>
   <div class="app-slide">
       <div class="slide-content" :class="[{'bounce': isActive}]">
          <slot></slot>
       </div>
   </div>
</template>
<script>
    export default {
      inject: ['slider'],
      data() {
        return {
          isActive: false,
        }
      },
      methods: {
        setActive(isActive) {
          this.isActive = isActive;
          const child = this.$children.length > 0? this.$children[0] : null;
          if(child && Object.prototype.hasOwnProperty.call(child, 'enterAnimation')){
            if(isActive) {
              child.enterAnimation();
            }
            else {
              child.leaveAnimation();
            }
          }
        }
      }
    }
</script>