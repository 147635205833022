<template>
    <div class="app-slider"  @wheel="scroll">
        <div class="app-slider__wrapper">
            <div class="app-slider__container" ref="container" :style="containerStyle">
                <slot></slot>
            </div>
        </div>
        <div class="updown-nav" v-if="isMainSlider">
            <div class="up-arrow" @click="prevSlide(false)">
                <span :style="{color: activeSlideIndex !== 0 ?'#000000':'rgba(0, 0, 0, 0.3)'}"></span>
            </div>
            <div class="down-arrow" @click="nextSlide(false)">
                <span :style="{color: activeSlideIndex < 4 ?'#000000':'rgba(0, 0, 0, 0.3)'}"></span>
            </div>
        </div>
        <div class="leftright-nav" v-if="isMainSlider">
          <div class="left-arrow" @click="prevSlide(false)">
            <span :style="{color: activeSlideIndex !== 0 ?'#000000':'rgba(0, 0, 0, 0.3)'}"></span>
          </div>
          <div class="right-arrow" @click="nextSlide(false)">
            <span :style="{color: activeSlideIndex < 4 ?'#000000':'rgba(0, 0, 0, 0.3)'}"></span>
          </div>
        </div>
        <div v-if="slideDirection==='H'" class="nav-bar">
            <div v-for="n in slideTotal" :key="n" v-bind:class="{'is-active': isControlActive(n)}" v-on:click="goToSlide(n - 1)" >
                <span></span>
            </div>
        </div>
    </div>
</template>
<script>
  import {debounce} from '../util/utility'
  export default {
    provide: {
      slider: this
    },
    props: {
      transitionDuration: {
        type: String,
        default: '0.5s'
      },
      transitionTiming: {
        type: String,
        default: 'ease'
      },
      slideDirection: {
        type: String,
        default: 'V'
      },
      isMainSlider: {default: true}
    },
    mounted() {
      this.$nextTick(function () {
        this.slideTotal = this.$refs.container.children.length
        this.isActive = this.isMainSlider ? true : false;
        this.setCurrentSlide();
      })
    },
    data() {
      return {
        isActive: false,
        slideTotal: null,
        activeSlideIndex: 0,
        currentSlide: null,
        touched: false,
        touch: {},
        swipeDirection: '',
        isSwipeHorizontal: false,

      };
    },
    methods: {
      goToSlide(slideIndex) {
        this.activeSlideIndex = slideIndex;
        this.setCurrentSlide();
        this.setActive(true)
      },
      setActive(isActive) {
        this.isActive = isActive;
        this.currentSlide.componentInstance.setActive(isActive)
      },
      isControlActive(controlIndex) {
        return controlIndex - 1 === this.activeSlideIndex;
      },
      isSubSlider() {
        return this.currentSlide && this.currentSlide.elm.className === 'app-slider'
      },
      scroll: debounce(function (e){
        if(e.deltaY > 0){
          this.nextSlide();
        }
        else {
          this.prevSlide();
        }
      }, 200),
      setCurrentSlide() {
        if(this.currentSlide) {
          this.currentSlide.componentInstance.setActive(false)
        }
        this.currentSlide = this.$slots.default[this.activeSlideIndex]
        if(this.isActive) {
          this.currentSlide.componentInstance.setActive(true)
        }
      },
      nextSlide(isSub = false) {
        if(isSub && this.currentSlide.componentInstance.nextSlide()) {
          return true;
        }
        else {
          if(this.activeSlideIndex < this.slideTotal - 1) {
            this.activeSlideIndex++;
            this.setCurrentSlide()
            return true;
          }
          else {
            return false;
          }
        }
      },
      prevSlide(isSub = false) {
        if(isSub && this.currentSlide.componentInstance.prevSlide()) {
          return true;
        }
        else {
          if(this.activeSlideIndex > 0) {
            this.activeSlideIndex--;
            this.setCurrentSlide()
            return true;
          }
          else {
            return false;
          }
        }
      }
    },
    computed: {
      containerSize() {
        return this.slideTotal * 100 + '%'
      },
      containerStyle() {
        return {
          width: this.slideDirection === 'V' ? '100%' : `${this.containerSize}`,
          height: this.slideDirection === 'V' ? `${this.containerSize}` : '100%',
          transform: this.slideDirection === 'V' ? `translateY(${this.activeSlidePosition}) ` : `translateX(${this.activeSlidePosition})`,
          transitionTimingFunction: `${this.transitionTiming}`,
          transitionDuration: `${this.transitionDuration}`,
          flexDirection: this.slideDirection === 'V' ? 'column': 'row'
        }
      },
      activeSlidePosition() {
        return '-' + (100/this.slideTotal) * this.activeSlideIndex + '%'
      }
    }
  }
</script>