<template>
    <div class="contact">
      <transition name="fade" enter-active-class="fadeUpIn" leave-active-class="fadeOutBottom">
        <div class="anim slide-title" v-if="isIn">CONTACT</div>
      </transition>
      <div class="inner-content">
        <div class="content-center">
          <div style="font-size: 1.5em; font-weight: bold">SAY HELLO!</div>
          <div style="font-size: 1em; margin-top: 20px">Have a project you’d like to discuss? I'd love to hear from you.</div>
          <span class="mail-info">
            <span><img src="@/assets/email.png" alt="eMail Icon"></span>
            <span class="mail-text" tooltip="Click to Copy" tooltip-position="bottom"><a href="mailto:helloiamjayesh@gmail.com">helloiamjayesh@gmail.com</a></span>
          </span>
          <div class="social-btns">
            <a class="sbtn" href="https://github.com/JayeshLab" target="_blank"><i class="fa fa-github"></i></a>
            <a class="sbtn vueforum" href="https://forum.vuejs.org/u/Jayesh" target="_blank"><img src="@/assets/vueforum.svg" alt="vue forum"></a>
            <!--<a class="sbtn" href="https://in.pinterest.com/jayesh2116/" target="_blank"><i class="fa fa-pinterest"></i></a>-->
            <a class="sbtn" href="skype:live:jayesh.vachhani?chat" target="_blank"><i class="fa fa-skype"></i></a>
            <a class="sbtn" href="https://codepen.io/Jayesh_v/" target="_blank"><i class="fa fa-codepen"></i></a>
            <a class="sbtn" href="http://jsfiddle.net/user/Jayesh_v/fiddles/" target="_blank"><i class="fa fa-jsfiddle"></i></a>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
  import viewMixin from './viewMixin'
	export default {
      mixins: [viewMixin]
	}
</script>