<template>
    <div id="app" @click="clicked">
        <Loader :loading="isLoading"></Loader>
        <Menu @change="(val) => menu = val" :menu="menu" @gotoslide="goToSlide" ></Menu>
        <Slider slide-direction="V" ref="slider">
            <Slide class="bg-1">
                <Home></Home>
            </Slide>
            <Slide class="bg-2">
                <About @goto="goToSlide"></About>
            </Slide>
            <Slide class="bg-3">
                <Skills></Skills>
            </Slide>
            <Slide class="bg-4">
                 <Work></Work>
            </Slide>
            <Slide class="bg-5">
                <Contact></Contact>
            </Slide>
        </Slider>
    </div>
</template>

<script>
  import Slider from './components/Slider.vue'
  import Slide from './components/Slide.vue'
  import Home from './views/Home.vue'
  import About from './views/About.vue'
  import Skills from './views/Skills.vue'
  import Work from './views/Work.vue'
  import Contact from './views/Contact.vue'
  import Loader from './components/Loader.vue'
  import  Menu from './components/Menu.vue'
  // eslint-disable-next-line 
  
  export default {
    name: 'App',
    data() {
      return {
        menu: false,
        isLoading: false,
        appHeight: null,
        appWidth: null
      }
    },
    components: {
      Slider,
      Slide,
      Home,
      About,
      Skills,
      Work,
      Contact,
      Loader,
      Menu
    },
    methods: {
      goToSlide(num) {
        this.$refs.slider.goToSlide(num)
      },
      getDimensions() {
        this.appWidth = window.innerWidth;
        this.appHeight = window.innerHeight;
      },
      clicked(e){
        if(!e.target.className.split(' ').some(c => /menu.*/.test(c))) {
          this.menu = false;
        }
      }
    },
    mounted() {
      this.isLoading = true;
      const self = this
      setTimeout(function() {
        self.isLoading = false;
      }, 2000)
      this.getDimensions();
    }
  }
</script>