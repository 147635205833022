<template>
    <div class="menu" id="menu" :class="{'in': menu}">
        <div class="wrapper-menu" :class="{'open': menu}" @click="$emit('change' , !menu)">
            <div class="line-menu half start"></div>
            <div class="line-menu"></div>
            <div class="line-menu half end"></div>
        </div>
        <ul class="menu__nav_list">
            <li v-for="(item, index) in menuList" :key="index">
                <span @click.stop="$emit('gotoslide', index)">{{item}}</span>
            </li>
        </ul>
    </div>
</template>
<script>
  export default {
    props: {
      menu: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        menuList: ['Home', 'About', 'Skills', 'My Work', 'Contact'],
      };
    }
  }
</script>