<template>
    <div class="home" style="text-align: center">
        <h1 class="title animated" :class="[{'fadeInDown': isIn, 'fadeOutUp': isOut}]">
           <TextAnim text="Hello"><span style="color: purple">.</span></TextAnim>
        </h1>
        <h1 class="title animated" :class="[{'fadeInDown': isIn, 'fadeOutUp': isOut}]"><TextAnim text="I  am"></TextAnim></h1>
        <div class="name-text animated" :class="[{'fadeInUp': isIn, 'fadeOutDown': isOut}]">JAYESH</div>
        <div class="profession-text animated" :class="[{'fadeInUp': isIn, 'fadeOutDown': isOut}]">&lt;&nbsp;&nbsp;&nbsp;&nbsp;FULLSTACK WEB DEVELOPER&nbsp;&nbsp;&nbsp;&nbsp;/&gt;</div>
        <BackgroundIconAnimation :count="16" v-if="isIn"></BackgroundIconAnimation>
    </div>
</template>
<script>
  import viewMixin from './viewMixin'
  import TextAnim from '../components/TextAnim.vue'
  import BackgroundIconAnimation from '../components/BackgroundIconAnimation.vue'
  export default {
    mixins: [viewMixin],
    components: {
      TextAnim,
      BackgroundIconAnimation
    }
  }
</script>