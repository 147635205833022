<template>
  <transition name="modal">
    <div class="modal-container" v-show="value">
      <div class="modal-close" @click="close"></div>
      <slot></slot>
    </div>
  </transition>
</template>
<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      close() {
        this.$emit('input', false);
      }
    }
  }
</script>