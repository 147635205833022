<template>
  <div class="wrap">
    <div v-for="i in count" :key="i" :class="['sym', i%2===0?'s-circle': 's-square']" aria-hidden="true"></div>
  </div>
</template>
<script>
  export default {
    props: {
      count: {
        type: Number,
        default: 10
      }
    }
  }
</script>