<template>
  <div class="about">
    <transition name="fade" enter-active-class="fadeUpIn" leave-active-class="fadeOutBottom">
      <div class="anim slide-title" v-if="isIn">ABOUT ME</div>
    </transition>
    <div class="inner-content">
      <div class="container-fluid scroll-container">
        <div class="row">
          <div class="col-12">
            <p>
            Hello, I am <b>Jayesh Vachhani</b> , an enthusiastic and passionate software developer who loves solving challenging problems and building great products with <b>14+ years</b> of experience in technical architect, designing, developing, implementing and supporting interactive web based applications.
              I specilizes in both frontend and backend technologies.</p>
            <p>
            I love building complex, clean, optimize & intuitive web application and always explore new trends to stay up to date. <br><br> I consider myself as <b>"swiss army knife"</b>
            contributing across multiple technology solutions, including front-end user application,  enterprise level scalable distributed system, hybrid and android mobile apps, end to end solutions for Internet of Things(IOT) including software and hardware and game developement.<br>
            Apart from technical skills, I have experience as a Team Lead in managing and mentoring development teams.<br> When I am not doing anything on computers, I love drawing, sketching, clay modeling.
            </p>
            <p>
            I had pleasure of working with some great organisation including Lehman Brothers, Nomura and JPMorgan.<br>
            I'm based in Mumbai, India. Working currently as consultant and freelancer. As a consultant providing strategic guidance and technical expertise to clients to meet their business objectives or resolve issues.<br><br> View <span class="link" @click="$emit('goto', 2)">my skills</span>
            and browse some of <span class="link" @click="$emit('goto', 3)">my work</span>. <br> Get in touch or talk about a project? Please feel free to <span class="link" @click="$emit('goto', 4)">contact me</span>.
            </p>
            <button type="button" class="btn btn-outline" onclick=" window.open('https://drive.google.com/file/d/1Ff2mmyEOeSfAwtWeCgmpOKL8eVo0syFz/view?usp=sharing', '_blank'); return false;">Download CV</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import viewMixin from './viewMixin'

  export default {
    mixins: [viewMixin]
  }
</script>